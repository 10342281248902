import styles from './Loading.module.scss';

type LoadingProps = {
  show: Boolean;
};

const Loading = ({ show }: LoadingProps) => {
  return (
    <div className={`${styles.modal} ${show ? styles.enterDone : styles.exit}`}>
      <div className={`${styles.loading}`}>
        <div className={`${styles.loadingio_spinner}`}>
          <div className={`${styles.ldio}`}>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
